.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
  z-index: 30;
  padding-left: calc(8px + env(safe-area-inset-left));
  padding-right: calc(8px + env(safe-area-inset-right));
}

.modalWrapper {
  width: 100%;
  height: calc(100% - 8px * 2 - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  padding-top: calc(8px + env(safe-area-inset-top));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
}

@media (orientation: landscape) {
  .modalWrapperForcePortrait {
    width: 100vh;
    height: 100vw;
  }
}
