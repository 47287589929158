.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
  z-index: 30;
}

.modalWrapper {
  width: 100%;
  height: 100%;
}
