.content {
  /* FIXME: flex-grow: 1; だと 3px 狂う */
  height: calc(100% - 80px);
  width: 100%;
  position: relative;
  background-color: #D8D8D8;
  border-top-left-radius: 5;
  border-top-right-radius: 5;
}
.canvas {
  /* iOS 14.5 で height が正しく適用されない問題があったため、TS 側でサイズ指定 */
  margin-left: calc(8px * 2);
  margin-right: calc(8px * 2);
  margin-top: calc(8px * 2);
  margin-bottom: calc(8px * 2);
}
